import styled from "styled-components"
import media from "styled-media-query";

export const GalleryRow = styled.div`
display: flex;

${media.lessThan("small")`
    display: block;
`}

`

export const Lightbox = styled.div`
margin: 5px;
transition: opacity 0.14s ease-in;
    cursor: pointer;

${media.lessThan("small")`
    margin: 0 0 10px 0;
`}

    &:hover {
        opacity: 0.9;
    }
`