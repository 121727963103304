import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Dialog, DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { useTransition, animated } from 'react-spring'

import * as S from "./styled"

var index = 0;
const GalleryRetrato = () => {

    const data = useStaticQuery(
        graphql`
        {
            allFile(filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "retrato"}}, sort: {fields: base, order: ASC}) {
                edges {
                node {
                    base
                    childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        sizes
                        src
                        aspectRatio
                        base64
                        srcSet
                    }
                    }
                }
                }
            }
        }
        `
    )

    // Dialog
    const open = (i) => {
        setShowDialog(true)
        index = i
    };
    const close = () => setShowDialog(false);

    const AnimatedDialogOverlay = animated(DialogOverlay);
    const AnimatedDialogContent = animated(DialogContent);
    const [showDialog, setShowDialog] = React.useState(false);
    const transitions = useTransition(showDialog, null, {
        from: { opacity: 0, y: -10 },
        enter: { opacity: 1, y: 0 },
        leave: { opacity: 0, y: 10 },
    });

    return (
        <>
            <S.GalleryRow className="gallery-row-portrait">
                <S.Lightbox className="item-33" onClick={() => open(0)}>
                    <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} className="gallery-item" alt="Fotografo Retrato | Mikio Fotos" />
                </S.Lightbox>
                <S.Lightbox className="item-33" onClick={() => open(1)}>
                    <Img fluid={data.allFile.edges[1].node.childImageSharp.fluid} className="gallery-item" alt="Fotografo Retrato | Mikio Fotos" />
                </S.Lightbox>
                <S.Lightbox className="item-33" onClick={() => open(2)}>
                    <Img fluid={data.allFile.edges[2].node.childImageSharp.fluid} className="gallery-item" alt="Fotografo Retrato | Mikio Fotos" />
                </S.Lightbox>
            </S.GalleryRow>
            <S.GalleryRow className="gallery-row-portrait">
                <S.Lightbox className="item-33" onClick={() => open(3)}>
                    <Img fluid={data.allFile.edges[3].node.childImageSharp.fluid} className="gallery-item" alt="Fotografo Retrato | Mikio Fotos" />
                </S.Lightbox>
                <S.Lightbox className="item-33" onClick={() => open(4)}>
                    <Img fluid={data.allFile.edges[4].node.childImageSharp.fluid} className="gallery-item" alt="Fotografo Retrato | Mikio Fotos" />
                </S.Lightbox>
                <S.Lightbox className="item-33" onClick={() => open(5)}>
                    <Img fluid={data.allFile.edges[5].node.childImageSharp.fluid} className="gallery-item" alt="Fotografo Retrato | Mikio Fotos" />
                </S.Lightbox>
            </S.GalleryRow>
            <S.GalleryRow>
                <S.Lightbox className="item-33 order-1" onClick={() => open(6)}>
                    <Img fluid={data.allFile.edges[6].node.childImageSharp.fluid} className="gallery-item" alt="Fotografo Retrato | Mikio Fotos" />
                </S.Lightbox>
                <S.Lightbox className="item-66" onClick={() => open(7)}>
                    <Img fluid={data.allFile.edges[7].node.childImageSharp.fluid} className="gallery-item" alt="Fotografo Retrato | Mikio Fotos" />
                </S.Lightbox>
            </S.GalleryRow>


            {transitions.map(
                ({ item, key, props: styles }) =>
                    item && (
                        <Dialog isOpen={showDialog} onDismiss={close}>
                            <AnimatedDialogOverlay style={{ opacity: styles.opacity }}>
                                <AnimatedDialogContent
                                    style={{
                                        transform: styles.y.interpolate(
                                            (value) => `translate3d(0px, ${value}px, 0px)`
                                        ),
                                    }}
                                >
                                    <Img fluid={data.allFile.edges[index].node.childImageSharp.fluid} />
                                </AnimatedDialogContent>
                                <button className="close-button" onClick={close}>
                                    <span aria-hidden>×</span>
                                </button>
                            </AnimatedDialogOverlay>
                        </Dialog>
                    )
            )}

        </>

    )

}

export default GalleryRetrato