import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import GalleryRetrato from "../components/Gallery/Retrato"

// colocar h1
const RetratoPage = () => (
  <Layout>
    <SEO 
      title="Fotografo Retrato" 
      description="Fotógrafo Retrato em São Paulo. Fotógrafo profissional com formação na escola Instituto Internacional de Fotografia, atuo em São Paulo nos segmentos da fotografia empresarial, eventos e retrato"
    />
    <h1 className="sr-only">Fotografo Retrato</h1>
    <GalleryRetrato />
  </Layout>
)

export default RetratoPage
